* {
	margin: 0;
	padding: 0;
}

body {
	overflow: hidden;
	.user-select(none);
	* {
		display: block;
	}
}

.center() {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%); 
}

.filter(@value) {
  filter: @value;
  -o-filter: @value;
  -ms-filter: @value;
  -moz-filter: @value;
  -webkit-filter: @value;
}

.user-select(@value) {
	user-select: @value;
	-o-user-select: @value;
	-moz-user-select: @value;
	-khtml-user-select: @value;
	-webkit-user-select: @value;
}

@sky-height: 50vh;
@sky-color: #1D70B2;
@night-color: black;
@twilight-color: orangered;
@transition-time: 750ms;
#sky {
	top: 0%;
	width: 100%;
	position: fixed;
	height: @sky-height;
	overflow: hidden;
	background: @sky-color;

	&.night {
		background: @night-color;
	}

	&.dawn, &.dusk {
		background: @twilight-color;
	}
}

#moon {
	width: 15vh;
	height: 15vh;
	top: 100%;
	fill: lightgrey;
	position: fixed;
	transform: translate(-50%, -50%);
}

#sun {
	fill: orange;
	stroke: orange;
	stroke-dasharray: .5;
	stroke-dashoffset: 0%;
	stroke-width: 10;
	
	width: 30vmin;
	height: 30vmin;
	position: fixed;
	transform: translate(-50%, -50%);
}

.cloud {
	@color: white;
	display: block;
	background: @color;
	position: fixed;
	border-radius: 50%;
	.filter(blur(10px));
	opacity: .3;
}

.season(@color) {
	background: @color;

	&.night {
		background: darken(@color, 25%);
	}
	&.dawn, &.dusk {
		background: darken(@color, 10%);
	}
}
#ground {
	@color: #92CC13;
	position: fixed;
	top: 50%;
	width: 100%;
	height: 100vh - @sky-height;
	background: @color;

	.season(@color);

	&.summer {
		.season(yellow);
	}

	&.autumn {
		.season(orange);
	}

	&.winter {
		.season(white);
	}
}

@egg-color: #FFFFEE;
@egg-border: lighten(black, 50%);
@egg-height: 300px;
@egg-width: 200px;

#egg {
   .center;
   display: block;
   width: @egg-width;
   height: @egg-height;
   background-color: @egg-color;
   border-radius: 50% ~"/" 60% 60% 40% 40%;
   border: medium solid @egg-border;
   overflow: hidden;

	&.night {
		background: mix(@egg-color, @night-color, 75%);
	}
	&.dawn, &.dusk {
		background: mix(@egg-color, @twilight-color, 75%);
	}

	&.open {
		opacity: 0;
	}
}

#egg .crack {
	display: inline-block;
	border-left: 2px solid @egg-border;
	@crack-size: @egg-width / 6;
	height: calc(@crack-size * sqrt(2));
	width: 0;
	margin-top: @egg-height * .6;
	transform-origin: 0 0;
	margin-right: calc(@crack-size - 2px);

	&:nth-child(even) {
		transform: translate(calc(@crack-size)) rotate(45deg);
	}
	&:nth-child(odd) {
		transform: rotate(-45deg);
	}
}

#reward {
	.center;
}
.nugget {
	.center;
	display: block;
	max-height: @egg-height;
	max-width: @egg-height;
	&.night {
		.filter(brightness(75%));
	}

	&.twilight {
		.filter(brightness(90%));
	}
}

svg {
	width: 100%;
	height: 100%;
}

#ground, #sky, #egg {
	transition: background-color 500ms;
}

.day > #sun, .night > #moon {
	top: 5%;
	left: 50%;
	transition: top @transition-time, left ease-in-out @transition-time;
}
.dusk > #sun, .dawn > #moon {
	top: 50%;
	left: 95%;
	transition: top ease-in-out @transition-time, left @transition-time;
}
.dawn > #sun, .dusk > #moon {
	top: 50%;
	left: 5%;
	transition: top ease-in-out @transition-time, left @transition-time;
}
.night > #sun, .day > #moon {
	top: 95%;
	left: 50%;
	right: 50%;
	transition: top @transition-time, left ease-in-out @transition-time;
}