* {
  margin: 0;
  padding: 0;
}

body {
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  overflow: hidden;
}

body * {
  display: block;
}

#sky {
  width: 100%;
  height: 50vh;
  background: #1d70b2;
  position: fixed;
  top: 0%;
  overflow: hidden;
}

#sky.night {
  background: #000;
}

#sky.dawn, #sky.dusk {
  background: #ff4500;
}

#moon {
  width: 15vh;
  height: 15vh;
  fill: #d3d3d3;
  position: fixed;
  top: 100%;
  transform: translate(-50%, -50%);
}

#sun {
  fill: orange;
  stroke: orange;
  stroke-dasharray: .5;
  stroke-dashoffset: 0%;
  stroke-width: 10px;
  width: 30vmin;
  height: 30vmin;
  position: fixed;
  transform: translate(-50%, -50%);
}

.cloud {
  filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
  -moz-filter: blur(10px);
  -webkit-filter: blur(10px);
  opacity: .3;
  background: #fff;
  border-radius: 50%;
  display: block;
  position: fixed;
}

#ground {
  width: 100%;
  height: 50vh;
  background: #92cc13;
  position: fixed;
  top: 50%;
}

#ground.night {
  background: #3f5708;
}

#ground.dawn, #ground.dusk {
  background: #719d0f;
}

#ground.summer {
  background: #ff0;
}

#ground.summer.night {
  background: #7f8000;
}

#ground.summer.dawn, #ground.summer.dusk {
  background: #cc0;
}

#ground.autumn {
  background: orange;
}

#ground.autumn.night {
  background: #805300;
}

#ground.autumn.dawn, #ground.autumn.dusk {
  background: #cc8400;
}

#ground.winter {
  background: #fff;
}

#ground.winter.night {
  background: #bfbfbf;
}

#ground.winter.dawn, #ground.winter.dusk {
  background: #e6e6e6;
}

#egg {
  width: 200px;
  height: 300px;
  background-color: #ffe;
  border: solid gray;
  border-radius: 50% / 60% 60% 40% 40%;
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

#egg.night {
  background: #bfbfb3;
}

#egg.dawn, #egg.dusk {
  background: #ffd1b3;
}

#egg.open {
  opacity: 0;
}

#egg .crack {
  height: 47.1405px;
  width: 0;
  transform-origin: 0 0;
  border-left: 2px solid gray;
  margin-top: 180px;
  margin-right: 31.3333px;
  display: inline-block;
}

#egg .crack:nth-child(2n) {
  transform: translate(33.3333px)rotate(45deg);
}

#egg .crack:nth-child(2n+1) {
  transform: rotate(-45deg);
}

#reward {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.nugget {
  max-height: 300px;
  max-width: 300px;
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.nugget.night {
  filter: brightness(75%);
  -o-filter: brightness(75%);
  -ms-filter: brightness(75%);
  -moz-filter: brightness(75%);
  -webkit-filter: brightness(75%);
}

.nugget.twilight {
  filter: brightness(90%);
  -o-filter: brightness(90%);
  -ms-filter: brightness(90%);
  -moz-filter: brightness(90%);
  -webkit-filter: brightness(90%);
}

svg {
  width: 100%;
  height: 100%;
}

#ground, #sky, #egg {
  transition: background-color .5s;
}

.day > #sun, .night > #moon {
  transition: top .75s, left .75s ease-in-out;
  top: 5%;
  left: 50%;
}

.dusk > #sun, .dawn > #moon {
  transition: top .75s ease-in-out, left .75s;
  top: 50%;
  left: 95%;
}

.dawn > #sun, .dusk > #moon {
  transition: top .75s ease-in-out, left .75s;
  top: 50%;
  left: 5%;
}

.night > #sun, .day > #moon {
  transition: top .75s, left .75s ease-in-out;
  top: 95%;
  left: 50%;
  right: 50%;
}

/*# sourceMappingURL=index.bbe659a4.css.map */
